<template>
  <v-container fluid>
    <v-card>
      <v-card-title>Pievienojiet uzņēmumu</v-card-title>
      <v-card-text>
        <v-form v-model="valid">
          <v-row>
            <v-col cols="12" lg="3">
              <v-text-field label="Nosaukums" v-model="form.name" outlined autofocus required :rules="rules"/>
            </v-col>
            <v-col cols="12" lg="3">
              <v-text-field label="Budžets" v-model="form.budget" outlined/>
            </v-col>
            <v-col cols="12" lg="3">
              <v-text-field label="Budžets datums" v-model="form.budget_date" outlined/>
            </v-col>
            <v-col cols="12" lg="3" class="d-flex justify-space-between">
              <v-switch v-model="form.ad_account" value="JKonsult" label="JKonsult"></v-switch>
              <v-switch v-model="form.ad_account" value="Klients" label="Klients"></v-switch>
            </v-col>
            <v-col cols="12" lg="4">
              <v-text-field label="Tālrunis" v-model="form.phone" outlined/>
            </v-col>
            <v-col cols="12" lg="4">
              <v-text-field label="E-pasts" v-model="form.email" outlined/>
            </v-col>
            <v-col cols="12" lg="4">
              <v-text-field label="Vaditajs" v-model="form.director" outlined/>
            </v-col>
            <v-col cols="12" lg="6">
              <v-text-field label="Juridiskā adrese" v-model="form.legal_address" outlined/>
            </v-col>
            <v-col cols="12" lg="6">
              <v-text-field label="Faktiskā adrese" v-model="form.actual_address" outlined/>
            </v-col>
            <v-col cols="12" lg="3">
              <v-text-field label="Kredītiestādes nosaukums" v-model="form.bank_name" outlined/>
            </v-col>
            <v-col cols="12" lg="2">
              <v-text-field label="Reģistrācijas numurs" v-model="form.reg_number" outlined/>
            </v-col>
            <v-col cols="12" lg="2">
              <v-text-field label="PVN maksātāja numurs" v-model="form.vat_reg_number" outlined/>
            </v-col>
            <v-col cols="12" lg="2">
              <v-text-field label="Kods SWIFT/BIC" v-model="form.bank_swift_bic" outlined/>
            </v-col>
            <v-col cols="12" lg="4">
              <v-text-field label="Konta numurs" v-model="form.bank_iban" outlined/>
            </v-col>
            <v-col cols="12" lg="4">
              <v-text-field label="Spreadsheet" v-model="form.spreadsheet" outlined/>
            </v-col>
            <v-col cols="12" lg="9">
              <v-textarea label="Apraksts" v-model="form.description" outlined/>
            </v-col>
            <v-col cols="12" lg="3">
              <v-color-picker
                v-model="form.color"
                mode="hexa"
                hide-inputs
                dot-size="30"
              ></v-color-picker>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <AlertResponse :response="alert"/>
      <v-card-actions>
        <v-btn :disabled="!valid" color="primary" @click="save">Save</v-btn>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
import axios from '@/axios'
import AlertResponse from '@/components/AlertResponse'

export default {
  name: 'CompanyCreate',
  components: {
    AlertResponse
  },
  data: () => ({
    valid: false,
    rules: [
      value => !!value || 'Nepieciešams'
    ],
    alert: null,
    form: {
      ad_account: '',
      name: '',
      description: '',
      phone: '',
      email: '',
      director: '',
      reg_number: '',
      vat_reg_number: '',
      legal_address: '',
      actual_address: '',
      bank_name: '',
      bank_swift_bic: '',
      bank_iban: '',
      budget: '',
      budget_date: '',
      color: '',
      sort_order: 100,
      status: 1,
      spreadsheet: ''
    }
  }),
  methods: {
    save () {
      if (this.valid === true) {
        axios.post('companies', this.form).then(response => {
          this.alert = response.data
          this.valid = false
          setTimeout(() => this.$router.push({ name: 'companyIndex' }), 2000)
        }).catch(error => {
          this.alert = error.data
          this.valid = true
        })
      }
    }
  }
}
</script>

<style scoped>

</style>
